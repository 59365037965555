import ApiService from '@services/ApiService'
import TelegramService from '@services/TelegramService'
import AnalyticsService from '@services/AnalyticsService'

/**
 * Init dependency container
 * @param {Object} config
 */
export default async config => {
  config = config || {}

  const tgService = new TelegramService()
  const apiService = new ApiService(config.api)
  const analyticsService = new AnalyticsService(config.analytics)

  try {
    await tgService.init()
    await apiService.initUser(tgService.user)

    analyticsService.init()
    analyticsService.setUserId(apiService.getUserId())

    // console.info('init', apiService.getUser())
  } catch (data) {
    console.error(data)
  }

  return { apiService, tgService, analyticsService }
}
