import axios from 'axios'
import { encrypt } from "@helpers/crypto.helper"

/**
 * API service
 */
export default class ApiService {
  /**
   * Init service
   * @param {Object} config
   */
  constructor(config) {
    this.config = config || {}
    this.user = {}
  }

  /**
   * client
   */
  get client() {
    return axios.create({
      baseURL: this.config.baseUrl,
      responseType: 'json',
      headers: {
        'X-Tg-User-Id': this.getUserId(),
      },
    })
  }

  getUserId() {
    return this.user.id
  }

  /**
   * getUser
   */
  getUser() {
    return this.user
  }

  /**
   * setUser
   */
  setUser(user) {
    this.user = user
  }

  /**
   * initUser
   * @param {Object} tgUser
   * @returns
   */
  async initUser(tgUser) {
    try {
      this.setUser({
        ...tgUser,

        id: tgUser.id > 0 ? encrypt(tgUser.id) : 0,
        first_name: tgUser.first_name !== undefined ? tgUser.first_name : 'Aza',
        last_name: tgUser.last_name !== undefined ? tgUser.last_name : 'Rasl',
        language_code: tgUser.language_code || 'ru',
      })

      const { data } = await this.client.post('tg/user/init', this.getUser())

      return data
    } catch (data) {
      console.error(data)

      throw data.message || ''
    }
  }

  /**
   * Отправить событие
   * @param {String} event
   * @param {Object} data
   * @return {Object}
   */
  async pushEvent(event, data) {
    data = data || {}
    data.userId = this.getUserId()

    try {
      await this.client.post('analyst/event/set', {
        category: 'WEB_APP',
        event: String(event).toUpperCase(),
        data,
      })
    } catch (error) {
      throw error
    }
  }

  async getQuestionList() {
    try {
      const { data } = await this.client.get('quiz/question/list')

      return data
    } catch (error) {
      throw error
    }
  }

  async checkAnswer({ question_id, variant_id }) {
    try {
      const { data } = await this.client.post('quiz/answer/check', { question_id, variant_id })

      return data
    } catch (error) {
      throw error
    }
  }

  async checkWinner() {
    try {
      const { data } = await this.client.get('quiz/win/check')

      return data
    } catch (error) {
      throw error
    }
  }

  async getPromocode() {
    try {
      const { data } = await this.client.get('promo-code/user')

      return data
    } catch (error) {
      throw error
    }
  }

  // async restartQuiz() {
  //   try {
  //     const { data } = await this.client.post('quiz/restart')

  //     return data
  //   } catch (error) {

  //     throw error
  //   }
  // }
}
