<script setup>
import { defineEmits, inject, computed } from 'vue'
import ButtonUi from '@uikit/ButtonUi'

const quizStore = inject('quizStore')
const emits = defineEmits(['to-route'])
const isWinner = computed(() => quizStore.getters.isWinner.value)

const startQuiz = async () => {
  await quizStore.actions.getQuestions()

  emits('to-route', 'quiz')
}
</script>

<template>
  <div class="wrapper">
    <div class="flex flex-col justify-between w-full h-full py-6 px-3">
      <div class="flex flex-col justify-between w-full h-full">
        <div v-if="isWinner">
          <p class="mb-2">Вы уже выиграли промокод, и можно расслабиться.</p>
          <p>Отвечайте на вопросы и узнавайте больше о проектах ПИК.</p>
        </div>
        <p v-else>Ответьте правильно на 3 или больше вопросов, и скидка ваша!</p>
        <div class="mb-8">
          <p class="mb-4">Готовы?</p>
          <p class="mb-4">На старт...</p>
          <p>Внимание...</p>
        </div>
      </div>
      <button-ui primary @click="startQuiz()">Марш</button-ui>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;

  overflow: hidden;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(@assets/images/icons/startViewBg.svg);
}

p {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -2%;
  font-weight: 500;
}
</style>
