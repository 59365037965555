<script setup>
import { defineEmits, onMounted, reactive, computed, inject, ref } from 'vue'
import AppTimer from '@components/AppTimer'
import ButtonUi from '@uikit/ButtonUi'

const emits = defineEmits(['push-answer', 'next-question', 'finish-quiz'])
const quizStore = inject('quizStore')
const image = ref(null)

const question = computed(() => quizStore.getters.question.value)
const isComplete = computed(() => quizStore.getters.isComplete.value)
const totalQuestions = computed(() => quizStore.getters.totalQuestions.value)
const timePerQuistion = computed(() => quizStore.getters.timePerQuistion.value)
const nextQuestionImage = computed(() => quizStore.getters.nextQuestion.value.img || null)

const state = reactive({
  step: 1,
  imageSrc: null,
  loadingImage: false,
  checked: false,
  isRight: false,
  cardTitle: '',
  cardInfo: '',
  timerId: null,
  timerProcess: true,
  timerDuration: timePerQuistion.value,
  timerValue: timePerQuistion.value,
})

const isNextDisabled = computed(() => state.loadingImage || !state.checked)
const isLastStep = computed(() => state.step === 5)

/**
 * stopTimer
 */
const stopTimer = () => {
  clearInterval(state.timerId)

  state.timerId = null
  state.timerProcess = false
}

/**
 * startTimer
 */
const startTimer = () => {
  /**
   * tick
   */
  const tick = () => (state.timerValue <= 0 ? toComplete(null) : state.timerValue--)

  state.timerId = setInterval(tick, 1000)
  state.timerProcess = true
  state.timerValue = timePerQuistion.value
}

/**
 * toComplete
 */
const toComplete = async variant => {
  stopTimer()

  if (!variant) {
    state.isRight = false
    state.cardTitle = 'Ошибка'
    state.cardInfo = 'Вы не выбрали ответ...'

    await quizStore.actions.checkAnswer({
      question_id: question.value.id,
      variant_id: 0,
    })
  } else {
    const result = await quizStore.actions.checkAnswer({
      question_id: question.value.id,
      variant_id: variant.id,
    })
    const answer = result.answer || {}

    state.isRight = !!answer.is_right
    state.cardTitle = state.isRight ? 'Верно!' : 'Неверно!'
    state.cardInfo = answer.description
  }

  state.checked = true
  setTimeout(() => changeImage(nextQuestionImage.value), 500)
}

const changeImage = imageSrc => {
  state.imageSrc = imageSrc
  state.loadingImage = true

  setTimeout(() => (state.loadingImage = false), 2000)
}

/**
 * checkVariant
 * @param {Object} event
 * @param {Object} variant
 */
const choiceVariant = async ({ target }, variant) => {
  await toComplete(variant)

  const element = target.closest('.variant')

  element.style.borderColor = state.isRight ? '#8DC73F' : '#FA5E54'
}

/**
 * nextQuestion
 */
const nextQuestion = () => {
  if (!state.checked) {
    return
  }

  document.querySelectorAll('.variant').forEach(({ style }) => {
    style.borderColor = 'transparent'
  })

  if (isComplete.value) {
    return emits('finish-quiz')
  }

  state.checked = false
  state.isRight = false
  state.cardTitle = ''
  state.cardInfo = ''
  state.step++

  emits('next-question')
  startTimer()
}

/**
 * onMounted
 */
onMounted(() => {
  startTimer()
  changeImage(question.value.img)

  image.value.addEventListener('load', () => {
    state.loadingImage = false
  })
})
</script>

<template>
  <div class="relative py-6 px-3 flex flex-col justify-between items-center" style="height: calc(100vh)">
    <app-timer
      :duration="state.timerDuration"
      :timer="state.timerValue"
      :style="{ height: '5px', width: '100%', position: 'absolute', inset: '0' }"
    />

    <div class="relative w-full h-full">
      <div class="flex items-center justify-between mb-4">
        <span class="font-medium"> {{ state.step }}/{{ totalQuestions }} </span>
        <span class="text-primary">
          <span class="px-2 py-1.5 rounded-md font-medium text-primary bg-white">00</span>
          :
          <span class="px-2 py-1.5 rounded-md font-medium text-primary bg-white">
            {{ String(state.timerValue).padStart(2, '0') }}
          </span>
        </span>
      </div>
      <div
        class="relative w-full h-40 heightXXS:h-44 heightXS:h-48 heightSSM:h-52 heightSM:h-56 mb-2 rounded-lg"
        style="perspective: 1000px"
      >
        <div
          class="absolute inset-0 backface-hidden"
          :style="{
            '-webkit-transition': '1s',
            '-moz-transition': '1s',
            '-o-transition': '1s',
            transition: '1s',
            '-webkit-transform': state.checked ? 'rotateX(180deg)' : 'rotateX(0)',
            '-moz-transform': state.checked ? 'rotateX(180deg)' : 'rotateX(0)',
            '-ms-transform': state.checked ? 'rotateX(180deg)' : 'rotateX(0)',
            '-o-transform': state.checked ? 'rotateX(180deg)' : 'rotateX(0)',
            transform: state.checked ? 'rotateX(180deg)' : 'rotateX(0)',
          }"
        >
          <img ref="image" :src="state.imageSrc" alt="" class="w-full h-full object-cover rounded-lg" />
        </div>
        <div
          :class="[
            'absolute inset-0 backface-hidden px-2 py-4 heightXS:px-4 heightXS:py-6 rounded-lg text-white',
            {
              'bg-danger': !state.isRight,
              'bg-success': state.isRight,
            },
          ]"
          :style="{
            '-webkit-transition': '1s',
            '-moz-transition': '1s',
            '-o-transition': '1s',
            transition: '1s',
            '-webkit-transform': state.checked ? 'rotateX(0)' : 'rotateX(180deg)',
            '-moz-transform': state.checked ? 'rotateX(0)' : 'rotateX(180deg)',
            '-ms-transform': state.checked ? 'rotateX(0)' : 'rotateX(180deg)',
            '-o-transform': state.checked ? 'rotateX(0)' : 'rotateX(180deg)',
            transform: state.checked ? 'rotateX(0)' : 'rotateX(180deg)',
          }"
        >
          <strong class="block mb-1 text-lg heightSM:text-xl font-semibold text-white">
            {{ state.cardTitle }}
          </strong>
          <p class="text-white text-sm heightSM:text-base">{{ state.cardInfo }}</p>
        </div>
      </div>
      <div class="mb-4 heightXS:mb-5 heightSSM:mb-6">
        <p
          class="h-[55px] font-medium mb-4"
          :style="{
            fontSize: '16px',
            lineHeight: '19px',
            // fontSize:
            //   question.question.length >= 80
            //     ? '15px'
            //     : question.question.length >= 70
            //     ? '15.5px'
            //     : question.question.length >= 60
            //     ? '16px'
            //     : question.question.length >= 50
            //     ? '16.5px'
            //     : '17px',
            // lineHeight:
            //   question.question.length >= 80
            //     ? '16px'
            //     : question.question.length >= 70
            //     ? '16.5px'
            //     : question.question.length >= 60
            //     ? '17px'
            //     : question.question.length >= 50
            //     ? '17.5px'
            //     : '18px',
          }"
        >
          {{ question.question }}
        </p>
        <div class="grid grid-cols-2 grid-rows-2 gap-2">
          <div
            v-for="variant in question.variants"
            :key="variant.variant"
            :class="[
              'variant h-[70px] heightSSM:h-[85px] heightSM:h-[95px] flex justify-center border-[2px] border-transparent rounded-xl text-center cursor-pointer transition-all bg-white',
              { 'pointer-events-none': state.checked },
            ]"
            @click="choiceVariant($event, variant)"
          >
            <span
              class="mx-auto mt-6 heightSSM:mt-8 heightSM:mt-10 px-2 heightSSM:px-3 heightSM:px-4"
              style="font-size: 14px; line-height: 16px"
              :class="{ 'widthXS:whitespace-nowrap': variant.variant.length <= 22 }"
            >
              {{ variant.variant }}
            </span>
          </div>
        </div>
      </div>

      <button-ui
        primary
        :disabled="isNextDisabled"
        @click="nextQuestion()"
        class="absolute left-0 bottom-0 text-sm py-2 heightXS:py-2.5 heightXS:text-base transition-all"
      >
        {{ isLastStep ? 'Показать результат' : 'Следующий вопрос' }}
      </button-ui>
    </div>
  </div>
</template>
