<script setup>
import { ref, computed, defineEmits, inject, watch } from 'vue'
import ButtonUi from '@uikit/ButtonUi'

const { apiService } = inject('dependency')
const quizStore = inject('quizStore')
const mainStore = inject('mainStore')
const emits = defineEmits(['to-route'])

console.log(mainStore)

const localAnswers = JSON.parse(localStorage.getItem(apiService.user.id))

const promocode = computed(() => quizStore.getters.promocode.value)
const totalRightAnswersCount = computed(() => quizStore.getters.totalRightAnswersCount.value || localAnswers?.right)

const isWinner = computed(() => quizStore.getters.isWinner.value)
const isFirstWin = computed(() => quizStore.getters.isFirstWin.value)
const hasWon = computed(() => totalRightAnswersCount.value >= quizStore.getters.scoreForWin.value)

const isPromoCopied = ref(false)
const isPromoTooltipTimerUp = ref(false)

const restart = async () => {
  quizStore.mutations.setTotalRightAnswersCount(0)
  quizStore.mutations.setTotalAnswersCount(0)
  quizStore.state.indexOfQuestion.value = 0

  return emits('to-route', 'start')
}

const copyPromocode = () => {
  try {
    navigator.clipboard.writeText(promocode.value.name)
    isPromoCopied.value = true
    setTimeout(() => (isPromoTooltipTimerUp.value = true), 2000)
  } catch (error) {
    console.log(error)
  }
}
</script>

<template>
  <div class="flex flex-col justify-between w-full h-full py-6 px-3">
    <div>
      <div class="absolute top-0 left-0 w-full h-1 bg-primary" />
      <p class="mt-2 mb-4 text-center text-primary">
        Верных ответов {{ totalRightAnswersCount }}/{{ quizStore.getters.totalQuestions.value }}
      </p>

      <div class="h-56 mx-auto mb-6 rounded-lg">
        <img
          v-if="hasWon"
          src="@assets/images/win.png"
          alt="win picture"
          class="w-full h-full object-cover rounded-lg"
        />
        <img v-else src="@assets/images/lose.png" alt="lose picture" class="w-full h-full object-cover rounded-lg" />
      </div>

      <div class="mb-10 text-center">
        <strong v-if="hasWon" class="block font-medium text-2xl mb-2">
          {{ isFirstWin ? 'Скидка 1% ваша!' : 'Ещё одна победа!' }}
        </strong>
        <strong v-else class="block font-medium text-2xl mb-2"> Увы, не всё прошло гладко </strong>

        <p v-if="hasWon" class="mb-6">
          {{
            isFirstWin
              ? 'Вы настоящий знаток проектов ПИК! А знатокам положены призы. Дарим промокод — он действует на все квартиры на сайте.'
              : 'Вы уже можете их коллекционировать. Но главный трофей и так ваш — промокод на скидку 1% ждёт вас в чате.'
          }}
          <small class="block mt-1 text-sm"> Действует до 9 мая включительно.</small>
        </p>
        <p v-else class="mb-6">
          {{
            isWinner
              ? 'Но мы поднимем вам настроение и напомним, что скидка 1% уже ваша. Промокод ждёт вас в чате.'
              : 'Но не расстраивайтесь! В следующий раз обязательно получится выиграть.'
          }}
        </p>

        <div v-if="hasWon" class="relative">
          <strong ref="promocode" class="block font-semibold mb-4 text-2xl uppercase text-primary">
            {{ promocode.name }}
          </strong>
          <span
            :class="[
              ' flex items-center justify-center mx-auto w-12 h-12 mb-4 rounded-full cursor-pointer transition-all',
              {
                'bg-white': !isPromoCopied,
                'bg-primary': isPromoCopied,
              },
            ]"
            @click="copyPromocode"
          >
            <img v-if="isPromoCopied" src="@assets/images/icons/copy(white).svg" alt="" />
            <img v-else src="@assets/images/icons/copy(primary).svg" alt="" />
          </span>
          <Transition>
            <span
              v-if="!isPromoTooltipTimerUp"
              v-show="isPromoCopied"
              class="absolute -bottom-8 left-0 right-0 w-fit mx-auto py-1 px-2 text-xs rounded-full bg-white/40"
            >
              Скопировано
            </span>
          </Transition>
        </div>
      </div>
    </div>

    <div>
      <button-ui class="mb-4" @click="restart()">Начать заново</button-ui>
      <button-ui v-if="isWinner" primary>
        <a
          href="#"
          class="block"
          @click.prevent="
            mainStore.actions.toLink(
              'https://www.pik.ru/search?utm_source=telegram&utm_medium=display&utm_campaign=general&utm_content=pik_quiz_bot'
            )
          "
        >
          Выбрать проекты
        </a>
      </button-ui>
      <button-ui v-else primary>
        <a
          href="#"
          class="block"
          @click.prevent="
            mainStore.actions.toLink(
              'https://www.pik.ru/search?utm_source=telegram&utm_medium=display&utm_campaign=general&utm_content=pik_quiz_bot'
            )
          "
        >
          Смотреть проекты
        </a>
      </button-ui>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
