import Start from '@views/Start'
import Finish from '@views/Finish'
import Quiz from '@views/Quiz'
import External from '@views/External'

export default {
  'start': Start,
  'finish': Finish,
  'quiz': Quiz,
  'external': External
}
