import { ref, computed } from 'vue'

export default ({ tgService, apiService, analyticsService }) => {
  const ERROR_MESSAGE_TIMEOUT = 3000
  const START_PAGE = 'start'
  const EXTERNAL_URL_PAGE = 'external'

  const state = {
    error: ref(null),
    route: ref(''),
    externalUrl: ref(null),
    history: ref([]),
  }
  const getters = {
    error: computed(() => state.error.value),
    route: computed(() => state.route.value),
    canBack: computed(() => state.route.value && state.route.value !== START_PAGE),
    history: computed(() => state.history.value),
    externalUrl: computed(() => state.externalUrl.value),
  }
  const mutations = {
    setError: error => (state.error.value = error),
    setRoute: route => (state.route.value = route),
    setHistory: history => (state.history.value = history),
    setExternalUrl: externalUrl => (state.externalUrl.value = externalUrl),
  }
  const actions = {
    /**
     * Инициализация
     * @param {Object} context
     */
    init: () => {
      tgService.app.ready()
      tgService.app.expand()
      // tgService.app.BackButton.onClick(() => actions.toBack())

      // actions.toRoute(START_PAGE)
      // actions.updateBackButton()
      actions.pushEvent({ event: 'OPEN', data: {} })
    },

    /**
     * Перейти к экрану
     * @param {Object} context
     * @param {String} route
     */
    toRoute: route => {
      actions.pushEvent({
        event: 'ROUTE_TO',
        data: { from: state.route.value, to: route },
      })

      mutations.setRoute(route)
      mutations.setHistory([...getters.history.value, route])

      // actions.updateBackButton()
    },

    /**
     * Перейти назад
     * @param {Object} context
     */
    toBack: () => {
      const history = getters.history.value.slice(0, -1)
      const route = history[history.length - 1]

      if (!route) {
        return
      }

      actions.pushEvent({
        event: 'ROUTE_TO',
        data: { from: getters.route.value, to: route },
      })

      mutations.setRoute(route)
      mutations.setHistory(history)

      // actions.updateBackButton()
    },

    /**
     * Перейти по ссылке
     * @param {Object} context
     * @param {String} url
     */
    toLink: url => {
      actions.pushEvent({
        event: 'ROUTE_TO_LINK',
        data: { from: getters.route.value, url },
      })

      tgService.app.openLink(url)
      // mutations.setExternalUrl(url)
      // mutations.setRoute(EXTERNAL_URL_PAGE)
      // mutations.setHistory([...getters.history.value, EXTERNAL_URL_PAGE])

      // actions.updateBackButton()
    },

    /**
     * Показать сообщение об ошибке
     * @param {Object} context
     * @param {String} error
     */
    showError: error => {
      console.error(error)

      mutations.setError(error)

      setTimeout(() => mutations.setError(null), ERROR_MESSAGE_TIMEOUT)
    },

    /**
     * Отправить событие
     * @param {Object} context
     */
    pushEvent: async ({ event, data }) => {
      console.info('Analytics::pushEvent', { event, data })

      try {
        await apiService.pushEvent(event, data)
        analyticsService.push(event, data)
      } catch (error) {
        actions.showError(error)
      }
    },

    /**
     * Обновить кнопку "назад"
     * @param {Object} context
     */
    updateBackButton: () => {
      getters.canBack.value ? tgService.app.BackButton.show() : tgService.app.BackButton.hide()
    },
  }
  
  return {
    state,
    getters,
    actions,
    mutations,
  }
}
