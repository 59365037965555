<script setup>
import mainStore from '@store/main'

const onLoaded = () => {}
</script>

<template>
  <div class="external-view relative flex flex-col justify-start items-stretch h-full">
    <iframe :src="mainStore.getters.externalUrl.value" class="external-view__frame w-full h-full" @load="onLoaded()" />
  </div>
</template>

<style scoped>
.external-view__frame {
  border: none;
  background: white;
}
.external-view__frame.loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
</style>
