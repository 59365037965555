/**
 * Сервис Telegram
 * https://core.telegram.org/bots/webapps
 */
export default class TelegramService {
  /**
   * Init
   * @return {TelegramService}
   */
  async init() {
    this.telegram = this.telegram || window.Telegram || null

    if (this.telegram) {
      return this
    }

    await new Promise((resolve, reject) => {
      const script = document.createElement('script')
      const timeoutId = setTimeout(() => reject(), 3000)

      script.setAttribute('src', 'https://telegram.org/js/telegram-web-app.js')
      script.addEventListener('load', () => {
        this.telegram = window.Telegram || null

        clearTimeout(timeoutId)
        resolve(this)
      })

      document.head.appendChild(script)
    })

    return Telegram.WebApp && Telegram.WebApp.enableClosingConfirmation()
  }

  /**
   * user
   * @return {Object}
   */
  get user() {
    const { user } = this.app.initDataUnsafe || {}

    return user || {}
  }

  /**
   * app
   * @return {Object}
   */
  get app() {
    return (this.telegram && this.telegram.WebApp) || {}
  }
}
