<script setup>
import { ref, computed, defineProps, onMounted, watch } from 'vue'

const props = defineProps({
  duration: { type: Number, required: true },
  timer: { type: Number, required: true },
  style: { type: Object, required: true },
})

const duration = computed(() => props.duration)
const timer = computed(() => props.timer)
const remainingStyle = ref(null)

const tick = () => {
  const percent = timer.value / duration.value * 100

  remainingStyle.value = {
    width: `${percent}%`
  }
}

watch(timer, () => tick())
onMounted(() => tick())
</script>

<template>
  <div class="timer" :style="style">
    <div class="timebar">
      <div class="timebar__background"></div>
      <div v-if="timer" class="timebar__remaining" :style="remainingStyle"></div>
    </div>
  </div>
</template>

<style>
.timebar {
  width: 100%;
  height: 100%;
  position: relative;
}

.timebar__background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #fa5e54;
  opacity: 0.3;
  z-index: 1;
}

.timebar__remaining {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #fa5e54;
  z-index: 2;
  -webkit-transition: width 1s linear;
  -moz-transition: width 1s linear;
  -o-transition: width 1s linear;
  transition: width 1s linear;
}
</style>
