/**
 * App config
 */
export default {
  api: {
    baseUrl: 'https://api.pik-quiz.dev.bot.swlt.ru',
  },
  analytics: {
    gtmId: '',
  },
}
