import { ref, computed } from 'vue'

export default ({ apiService }) => {
  const state = {
    logs: ref([]),
    timePerQuistion: ref(59),
    scoreForWin: ref(3),
    isWinner: ref(null),
    isFirstWin: ref(null),
    indexOfQuestion: ref(0),
    question: ref(null),
    questions: ref([]),
    totalAnswersCount: ref(0),
    totalRightAnswersCount: ref(0),
    promocode: ref(null),
    error: ref(null),
  }

  const totalQuestions = computed(() => 5)
  const indexOfLastQuestion = computed(() => state.questions.value.length - 1)
  const indexOfNextQuestion = computed(() => state.indexOfQuestion.value + 1)

  const getters = {
    totalQuestions,
    logs: computed(() => state.logs.value),
    timePerQuistion: computed(() => state.timePerQuistion.value),
    scoreForWin: computed(() => state.scoreForWin.value),
    isWinner: computed(() => state.isWinner.value),
    isFirstWin: computed(() => state.isFirstWin.value),
    question: computed(() => state.questions.value[state.indexOfQuestion.value] || {}),
    nextQuestion: computed(() => state.questions.value[indexOfNextQuestion.value] || {}),
    questions: computed(() => state.questions.value),
    totalAnswersCount: computed(() => state.totalAnswersCount.value),
    totalRightAnswersCount: computed(() => state.totalRightAnswersCount.value),
    isComplete: computed(() => state.totalAnswersCount.value === totalQuestions.value),
    promocode: computed(() => state.promocode.value || { name: 'NONE' }),
    indexOfQuestion: computed(() => state.indexOfQuestion.value),
    indexOfLastQuestion,
    indexOfNextQuestion,
    error: computed(() => state.error.value),
  }

  const mutations = {
    setQuestions: questions => (state.questions.value = questions),
    setTotalAnswersCount: count => (state.totalAnswersCount.value = count),
    setTotalRightAnswersCount: count => (state.totalRightAnswersCount.value = count),
    setWinner: data => (state.isWinner.value = data),
    setFirstWin: data => (state.isFirstWin.value = data),
    setPromocode: promocode => (state.promocode.value = promocode),
    setError: error => (state.error.value = error),
  }

  const log = data => {
    state.logs.value.push(data)
  }

  const actions = {
    log,
    nextQuestion: () => {
      if (getters.indexOfQuestion.value >= indexOfLastQuestion.value) {
        state.indexOfQuestion.value = indexOfLastQuestion.value
      } else {
        state.indexOfQuestion.value++
      }
    },
    getQuestions: async () => {
      try {
        const questions = await apiService.getQuestionList()

        mutations.setQuestions(questions)

        return questions
      } catch (error) {
        log(error)
      }
    },
    checkAnswer: async ({ question_id, variant_id }) => {
      try {
        const result = await apiService.checkAnswer({ question_id, variant_id })

        mutations.setTotalAnswersCount(result.total_answer_count)
        mutations.setTotalRightAnswersCount(result.is_right_answer_count)

        return result
      } catch (error) {
        log(error)
      }
    },
    checkWinner: async () => {
      try {
        const result = await apiService.checkWinner()

        const correctAnswers = result.attempt.filter(att => !!att.is_win)

        mutations.setFirstWin(correctAnswers.length === 1)
        mutations.setWinner(result.is_win)

        return result
      } catch (error) {
        log(error)
      }
    },
    getPromocode: async () => {
      try {
        const promocode = await apiService.getPromocode()

        mutations.setPromocode(promocode)

        return promocode
      } catch (error) {
        log(error)
      }
    },
  }

  return { state, getters, actions, mutations }
}
