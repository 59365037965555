import { createApp } from 'vue'
import App from '@/App'
import initDependency from '@/dependency'
import config from '@config'
import quizStore from '@store/quiz'
import mainStore from '@store/main'
import '@assets/fonts/stylesheet'
import '@assets/css/style'

/**
 * Init app
 */
initDependency(config).then(dependency => {
  const app = createApp(App)

  app.provide('dependency', dependency)
  app.provide('quizStore', quizStore(dependency))
  app.provide('mainStore', mainStore(dependency))

  app.mount('#app')
})
