<script setup>
import { computed, onMounted, inject } from 'vue'
import routes from '@routes'
import NotFound from '@views/NotFound'

const mainStore = inject('mainStore')
const quizStore = inject('quizStore')

const route = computed(() => mainStore.getters.route.value)
const currentView = computed(() => routes[route.value] || NotFound)

const toRoute = (route = '') => mainStore.actions.toRoute(route)

onMounted(async () => {
  mainStore.actions.init()

  await quizStore.actions.checkWinner()
  await quizStore.actions.getPromocode()

  toRoute('start')
})
</script>

<template>
  <div class="max-w-full h-screen min-h-full mx-auto overflow-x-hidden relative">
    <!-- <pre style="position: absolute; top: 0; left: 0; z-index: 100; width: 100%; height: 150px; padding: 20px; font-size: 18px; background: white; overflow-y: auto; border-bottom: 3px solid red;">
      {{ logs }}
    </pre>   
     -->
    <Transition name="app" appear>
      <component v-if="route" :is="currentView" @to-route="toRoute" />
    </Transition>
  </div>
</template>

<style>
.app-enter-active,
.app-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.app-enter-from,
.app-leave-to {
  opacity: 0;
}
</style>
