import global from './global'
import local from './local'

/**
 * App config
 */
export default {
  api: {
    ...global.api,
    ...local.api,
  },

  analytics: {
    ...global.analytics,
    ...local.analytics,
  },
}
