/**
 * App config
 */
export default {
  api: {
    baseUrl: 'https://api.pik-quiz.prod.bot.swlt.ru',
  },

  analytics: {
    gtmId: 'GTM-WTR59XV',
  },
}
