<script setup>
import { defineEmits, inject } from 'vue'
import AppQuizItem from '@components/AppQuizItem'

const { apiService } = inject('dependency')
const quizStore = inject('quizStore')
const emits = defineEmits(['to-route'])

const nextQuestion = () => {
  quizStore.actions.nextQuestion()
}

const finishQuiz = async () => {
  localStorage.setItem(
    apiService.user.id,
    JSON.stringify(
      {
        total: quizStore.getters.totalAnswersCount.value,
        right: quizStore.getters.totalRightAnswersCount.value,
      },
      null,
      4
    )
  )

  await quizStore.actions.checkWinner()
  await quizStore.actions.getPromocode()

  emits('to-route', 'finish')
}

</script>

<template>
  <app-quiz-item @next-question="nextQuestion" @finish-quiz="finishQuiz" />
</template>
