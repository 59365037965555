/**
 * Сервис аналитики
 */
export default class AnalyticsService {
  /**
   *
   * @param {*} config
   */
  constructor(config) {
    this.config = config || {}
    this.gtmId = this.config.gtmId
    this.userId = this.config.userId
    this.isReady = false
  }

  get enabled() {
    return !!this.gtmId
  }

  /**
   *
   * @returns
   */
  async init() {
    if (!this.enabled || this.isReady) {
      return this
    }

    return await new Promise((resolve, reject) => {
      const firstScript = document.getElementsByTagName('script')[0]
      const gtmScript = document.createElement('script')
      const timeoutId = setTimeout(() => reject(), 3000)

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

      gtmScript.setAttribute('async', true)
      gtmScript.setAttribute('src', `https://www.googletagmanager.com/gtm.js?id=${this.gtmId}`)
      gtmScript.addEventListener('load', () => {
        this.isReady = true

        clearTimeout(timeoutId)
        resolve(this)
      })

      firstScript.parentNode.insertBefore(gtmScript, firstScript)
    })
  }

  /**
   *
   * @param {*} userId
   */
  setUserId(userId) {
    this.userId = userId
  }

  /**
   * Отправить событие
   * @param {*} event
   * @param {*} data
   * @returns
   */
  push(event, data) {
    data = data || {}
    data.userId = this.userId || 0

    if (!this.enabled) {
      return
    }

    window.dataLayer.push({ ...data, event })
  }
}
